import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

function canonical(string) {
  if (!isVariableDefinedNotNull(string)) return null;

  if (string.normalize) {
    return string
      .toLowerCase()
      .normalize('NFKD')
      .replace(/[^a-z0-9 ]/gi, '')
      .replace(/ +/g, '-')
      .replace(/-+/, '-');
  }

  return string;
}

export default canonical;
